body {
   margin: 0;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
}

.header-div {
   position: sticky;
   top: 0;
   z-index: 1040;
   background: white;
   font-size: 0;
}

.top-image {
   margin-left: 1%;
   margin-right: 1%;
   width: 98%;
   margin-bottom: 0px;
   height: 5px;
   z-index: 1040;
}

.nav-link-spacing {
   margin-left: 3vw;
   /* Abstand nach links */
   margin-right: 1vw;
   /* Abstand nach rechts */
   z-index: 1030;

}

.navbar-divider {
   width: 1px;
   background-color: rgb(105, 102, 102);
   /* Farbe der Linie anpassen */
   margin: 0 15px;
   /* Abstand zu den Elementen links und rechts */
   align-self: stretch;
   /* Linie erstreckt sich über die volle Höhe */
}

.navbar-font {
   font-family: 'Ubuntu', sans-serif;
   font-weight: 700;
}

.navbar-custom {
   background-color: #feffff00;
}


.multirose-flat-top {
   opacity: 1;
   fill: #cc0000;
}

.multirose-flat-middle {
   opacity: .9;
   fill: #cc0000;
}

.multirose-flat-bottom {
   opacity: .8;
   fill: #cc0000;
}

.multirose-side-top {
   opacity: 1;
   fill: #fb6565;
}

.multirose-side-middle {
   opacity: .9;
   fill: #fb6565;
}

.multirose-side-bottom {
   opacity: .8;
   fill: #fb6565;
}

.region-image {
   max-width: 450px;
   max-height: 100%;
   width: 80%;
   margin: 5%;
   border-style: solid;
   border-width: 1px;
}
